<template>
  <div class="d-flex justify-content-between">
    <div class="d-flex">
      <ion-button class="back-button" @click="back">
        <ion-icon class="icon" :icon="arrowBackOutline" />
      </ion-button>
    </div>
    <div class="title text-black mt-2">
      {{ $route.name === 'social-space-trending' ? 'Trending' : $route.name === 'social-space-top' ? 'Top' : 'Latest' }}
      Social Spaces
    </div>
    <div></div>
  </div>
  <div class="mt-3">
    <vertical-social-space-card
      v-for="(space, index) of socialSpace"
      :key="index"
      :index="index"
      :room-counts="get(roomCounts, `${space.id}.room_count`) || 0"
      :member-counts="get(memberCounts, `${space.id}.member_count`) || 0"
      :space="space"
      @reaction="onReaction"
    />
    <div class="d-flex justify-content-center clickable-item-hov">
      <ChLoading size="sm" v-if="isLoading" class="spinner" />
      <p v-else v-show="paging.next" @click="nextPage">Load More</p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import VerticalSocialSpaceCard from '@/shared/components/VerticalSocialSpaceCard.vue';
import { arrowBackOutline } from 'ionicons/icons';
import {
  getNextPage,
  getSocialSpaceRoomsCount,
  getTopWorlds,
  getWorldListings,
  getWorldsMemberCounts,
} from '@/shared/actions/worlds';
import { Paging, SocialSpace } from '@/shared/types/static-types';

const router = useRouter();

defineProps({
  sectionTitle: {
    type: String,
    required: true,
  },
  toTop: {
    type: Boolean,
    default: false,
  },
});
const socialSpace: any = ref([]);
const isLoading = ref(false);
const paging = ref({
  next: null,
  previous: null,
  count: 0,
  page: 1,
}) as Ref<{ next: string | null; previous: string | null; count: number; page: number }>;

const route = useRoute();
const memberCounts = ref({});
const roomCounts = ref({});

const nextPage = async () => {
  isLoading.value = true;
  const data = await getNextPage(paging.value as Paging);
  socialSpace.value = [...socialSpace.value, ...data.results];
  paging.value = data;
  isLoading.value = false;
  fetchSocialSpacesMemberCounts();
  fetchSocialSpaceRoomCounts();
};

onMounted(async () => {
  fetchSocialSpaces(route.name);
});

const back = () => {
  router.go(-1);
};

const onReaction = (event: { reactResp: {}; social: SocialSpace }) => {
  const index = socialSpace.value.findIndex((char: any) => char.id === event.social.id);
  socialSpace.value[index] = { ...event.social, ...get(event.reactResp, 'updatedReactionsData', {}) };
};
const fetchSocialSpaces = async (routeName: any) => {
  let resp: any;
  isLoading.value = true;
  routeName === 'social-space-trending'
    ? (resp = await getTopWorlds(1, 'socialspace', '-hot_score', 6))
    : routeName === 'social-space-top'
    ? (resp = await getTopWorlds(1, 'socialspace', '-rxn_count', 6))
    : (resp = await getWorldListings(1, 5, 'socialspace'));
  socialSpace.value = resp.results;
  paging.value = resp;
  fetchSocialSpacesMemberCounts();
  fetchSocialSpaceRoomCounts();
  isLoading.value = false;
};

const fetchSocialSpacesMemberCounts = async () => {
  memberCounts.value = {};
  const resp = await getWorldsMemberCounts(map(socialSpace.value, 'id') as string[]);
  memberCounts.value = keyBy(resp, 'world_id');
};
const fetchSocialSpaceRoomCounts = async () => {
  const resp = await getSocialSpaceRoomsCount(map(socialSpace.value, 'id') as string[]);
  roomCounts.value = keyBy(resp, 'world_id');
};
</script>

<style lang="sass" scoped>
.spinner
  width: 45px
  height: 45px
.chatroom-btn
  --border-radius: 15px
  height: 22px
.text
  font-size: 12px
  margin-top: 0.22rem

.social-space-chatrooms
  overflow-x: auto
.section-title
  font-size: 36px
  font-weight: bold
  margin-bottom: 12px
  margin-top: 30px
.title
  font-size: 20px
  font-weight: bold
.description
  font-size: 14px
.icon
  font-size: 16px
  font-weight: bold
.back-button
  --background: #431c89
  --border-radius: 8px
.chat-room-card
  overflow: unset

  @media(min-width: 420px)
    min-width: 100% !important
    max-width: 28% !important
  @media(max-width: 419px)
    min-width: 100% !important
    max-width: 50% !important
  .img
    width: 65px !important
    height: 65px !important
    border-radius: 35px
    border: solid gray 0.1px
    object-fit: cover

.chat-rooms
  overflow-x: auto
  overflow: unset
.chat-rooms::-webkit-scrollbar
  display: none
.chat-rooms
  -ms-overflow-style: none
  scrollbar-width: none
</style>
